
import axios from 'axios';
import { IAutoLabelModel, IGetAutoLabelResult, IGetDocumentServiceModel } from "../models/applicationState";
import {
    parseEndpointByType,
} from "../react/components/common/jsonSchemaFormHelper";

export interface IAutoLabelModelManagementService {
    get(): Promise<IGetAutoLabelResult>;
    getById(modelId: string): Promise<IAutoLabelModel>;
    getDocumentServicesByModelId(modelId: string): Promise<IGetDocumentServiceModel[]>;
    generateModel(documentSeviceId: string): Promise<boolean>;
    save(autoLabelModel: IAutoLabelModel): Promise<boolean>;
    resetAutoLabelModel(modelId: string): Promise<boolean>;
}

export default class AutoLabelModelManagementService implements IAutoLabelModelManagementService {    
    public async get() {
        return new Promise<IGetAutoLabelResult>(async (resolve, reject) => {
            try {
                const autoLabelModels = await axios.get<IGetAutoLabelResult>(parseEndpointByType('auto-label-models'));
                if (autoLabelModels) {
                    resolve(autoLabelModels.data);
                }                
                else {
                    reject();
                }
            }
            catch(ex) {
                reject(ex);
            }
        })
    }

    public async getById(modelId: string): Promise<IAutoLabelModel> {
        return new Promise<IAutoLabelModel>(async (resolve, reject) => {
            try {
                const autoLabelModels = await axios.get<IAutoLabelModel>(parseEndpointByType(`auto-label-models/${modelId}`));
                if (autoLabelModels) {
                    resolve(autoLabelModels.data);
                }                
                else {
                    reject();
                }
            }
            catch(ex) {
                reject(ex);
            }
        })
    }

    public async getDocumentServicesByModelId(modelId: string): Promise<IGetDocumentServiceModel[]> {
        console.log('getDocumentServiceIdsByModelId');
        return new Promise<IGetDocumentServiceModel[]>(async (resolve, reject) => {
            try {
                const docServices = await axios.get<IGetDocumentServiceModel[]>(parseEndpointByType(`document-services/${modelId}`));
                if (docServices) {
                    resolve(docServices.data);
                }                
                else {
                    reject([]);
                }
            }
            catch(ex) {
                reject(ex);
            }
        })
    }

    public async generateModel(documentSeviceId: string): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            try {
                const result = await axios.get<boolean>(parseEndpointByType(`auto-label-model/generate/${documentSeviceId}`));
                if (result) {
                    resolve(result.data);
                }                
                else {
                    reject([]);
                }
            }
            catch(ex) {
                reject(ex);
            }
        })
    }

    public async save(autoLabelModel: IAutoLabelModel): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            try {
                const result = await axios.post<boolean>(parseEndpointByType('auto-label-model'), autoLabelModel);
                console.log('Save result', result);
                if (result) {
                    resolve(result.data);
                }                
                else {
                    reject([]);
                }
            }
            catch(ex) {
                reject(ex);
            }
        })
    }

    public async resetAutoLabelModel(modelId: string): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            try {
                const result = await axios.post<boolean>(parseEndpointByType(`auto-label-model/${modelId}/reset`));
                console.log('Reset result', result);
                if (result) {
                    resolve(result.data);
                }                
                else {
                    reject([]);
                }
            }
            catch(ex) {
                reject(ex);
            }
        })
    }
}