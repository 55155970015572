import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { IApplicationState, IAutoLabelModel, IAutoLabelState, IGetDocumentServiceModel } from "../../../../models/applicationState";
import { bindActionCreators } from "redux";
import IAutoLabelModelManagementActions, * as autoLabelActions from "../../../../redux/actions/autoLabelModelManagementActions";
import GenerateModelModal from "./generateModelModal";
import { toast } from "react-toastify";

interface IAutoLabelModelManagementPageState {
    showGenerateModal: boolean,
    selectAutoLabel: IAutoLabelModel | {}, 
    documentServices: IGetDocumentServiceModel[],
    selectedDocumentServiceId: string
}

interface IAutoLabelModelManagementPageProps extends RouteComponentProps, React.Props<AutoLabelModelManagementPage> {
    autoLabelState: IAutoLabelState,
    autoLabelModelActions: IAutoLabelModelManagementActions
}


function mapStateToProps(state: IApplicationState) {
    return {
        autoLabelState: state.autoLabelState
    }
}

function mapDispatchToProps(dispatch) {
    return {
        autoLabelModelActions: bindActionCreators(autoLabelActions, dispatch)
    }
}

@connect(mapStateToProps, mapDispatchToProps)
export default class AutoLabelModelManagementPage extends React.Component<IAutoLabelModelManagementPageProps, IAutoLabelModelManagementPageState> {
    constructor(props) {
        super(props);

        this.state = {
            showGenerateModal: false, 
            selectAutoLabel: {}, 
            documentServices: [], 
            selectedDocumentServiceId: ''
        }

        this.toggleGenerateModal = this.toggleGenerateModal.bind(this);
        this.selectAutoLabel = this.selectAutoLabel.bind(this);
        //this.onGenerateModel = this.onGenerateModel.bind(this);
        this.onSelectDocumentServiceChange = this.onSelectDocumentServiceChange.bind(this);
        this.getAutoLabelVersions = this.getAutoLabelVersions.bind(this);
        this.selectAutoLabelChange = this.selectAutoLabelChange.bind(this);
        this.saveAutoLabel = this.saveAutoLabel.bind(this);
        this.resetAutoLabel = this.resetAutoLabel.bind(this);
    }

    async componentDidMount() {
        await this.props.autoLabelModelActions.get();
    }

    toggleGenerateModal(show: boolean) {
        const { showGenerateModal } = this.state;
        this.setState({
            showGenerateModal: show
        });
    }

    async selectAutoLabel(modelId: string) {
        const autoLabel = await this.props.autoLabelModelActions.getById(modelId);
        const documentServices = await this.props.autoLabelModelActions.getDocumentServicesByModelId(modelId);
        this.setState({
            selectAutoLabel: autoLabel,
            documentServices: documentServices
        }, () => {
            this.toggleGenerateModal(true);
        });        
    }

    async saveAutoLabel() {
        const { selectAutoLabel } = this.state;
        const saveResult = await this.props.autoLabelModelActions.save(selectAutoLabel as IAutoLabelModel);
        if (saveResult) {
            toast.info('Save successful');
        }        
    }

    async resetAutoLabel(): Promise<boolean> {
        const { selectAutoLabel } = this.state;
        let autoLabelModel = selectAutoLabel as IAutoLabelModel;
        const result = await this.props.autoLabelModelActions.resetAutoLabelModel(autoLabelModel.modelId);
        if (result) {
            toast.info('Reset successful');
            await this.getAutoLabelVersions();
            return true;
        }        
        else {
            toast.error('Failed to reset model');
            return false;
        }        
    }

    selectAutoLabelChange(name: string, value: string) {
        const { selectAutoLabel } = this.state;
        selectAutoLabel[name] = value;
        this.setState({
            selectAutoLabel
        });
    }

    async getAutoLabelVersions() {
        const selectAutoLabel = this.state.selectAutoLabel as IAutoLabelModel;
        if (selectAutoLabel && selectAutoLabel.modelId) {
            const documentServices = await this.props.autoLabelModelActions.getDocumentServicesByModelId(selectAutoLabel.modelId);
            this.setState({
                documentServices: documentServices
            });
        }
    }

    // async onGenerateModel() {
    //     const result = this.props.autoLabelModelActions.generateModel(this.state.selectedDocumentServiceId);
    //     if (result) {
    //         toast.info('Please wait a few minutes and then refresh your browser, generating auto label model is in progress.', { autoClose: 5000 });
    //         await this.props.autoLabelModelActions.get();
    //     }
    //     else {
    //         toast.error('An error occured while processing your request.', { autoClose: 5000 });
    //     }
    // }

    onSelectDocumentServiceChange(id: string) {
        this.setState({
            selectedDocumentServiceId: id
        })
    }

    render() {
        const { showGenerateModal, documentServices, selectedDocumentServiceId } = this.state;
        const selectAutoLabel = this.state.selectAutoLabel as IAutoLabelModel;
        
        
        return (
            <React.Fragment>
                <div className="container" style={{marginTop: '25px', backgroundColor: '#d1e3f6', overflow: 'scroll'}}>
                    <style>
                        {`                    
                        body {
                            font-size: 0.8375rem;
                        }
                        .form-control {
                            font-size: 0.8375rem;
                        }
                        .modal-dialog {
                            max-width: 800px;
                        }
                        `}
                    </style>
                    <div className="row" >
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">Model Id</th>
                                    <th scope="col">Document Type</th>
                                    <th scope="col">Jurisdiction</th>
                                    {/* <th scope="col">Model Name</th> */}
                                    <th scope="col"># of Attachments</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                { this.props.autoLabelState && this.props.autoLabelState.autoLabelModels && this.props.autoLabelState.autoLabelModels.length > 0  &&
                                    (
                                        this.props.autoLabelState.autoLabelModels.map(item => {
                                            let jurisdiction = '';
                                            if (item.countryCode && item.municipality && item.provinceCode) {
                                                jurisdiction = `${item.municipality}-${item.provinceCode}-${item.countryCode}`;
                                            }
                                            return (
                                                <tr key={item.modelId}>
                                                    <td scope="row">{item.modelId}</td>
                                                    <td>{item.documentType}</td>
                                                    <td>{jurisdiction}</td>
                                                    {/* <td>{item.modelName}</td> */}
                                                    <td>{item.attachments}</td>
                                                    <td>
                                                        <button className="btn btn-sm btn-primary" onClick={() => this.selectAutoLabel(item.modelId)}>
                                                            Details
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        })                                
                                    )
                                }
                            </tbody>
                        </table>
                    </div>                          
                </div>

                <GenerateModelModal 
                    showGenerateModal={showGenerateModal}
                    selectAutoLabel={selectAutoLabel}
                    documentServices={documentServices}
                    selectedDocumentServiceId={selectedDocumentServiceId}
                    toggleGenerateModal={this.toggleGenerateModal}
                    onGenerateModel={this.props.autoLabelModelActions.generateModel}
                    onSelectDocumentServiceChange={this.onSelectDocumentServiceChange}
                    get={this.props.autoLabelModelActions.get}
                    getAutoLabelVersions={this.getAutoLabelVersions}
                    selectAutoLabelChange={this.selectAutoLabelChange}
                    saveAutoLabel={this.saveAutoLabel}
                    resetAutoLabel={this.resetAutoLabel}
                />
            </React.Fragment>
            
        )
    }
}